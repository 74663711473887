import { useState, useEffect, useMemo } from 'react';

export const useFilterState = (type, params) => {
    const defaultFromDate = useMemo(() => {
        if (type === "graph") {
            const now = new Date();
            const fromDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 14);
            return fromDate.toLocaleDateString("en-CA");
        }
        return "";
    }, [type]);

    const [fromDate, setFromDate] = useState(defaultFromDate);
    const [toDate, setToDate] = useState("");
    const [klass, setKlass] = useState([]);
    const [lesson, setLesson] = useState([]);
    const [lessonCategory, setLessonCategory] = useState([]);
    const [fromSeconds, setFromSeconds] = useState("");
    const [toSeconds, setToSeconds] = useState("");
    const [extension, setExtension] = useState("");
    const [messageName, setMessageName] = useState("");
    const [identityNumber, setIdentityNumber] = useState("");
    const [phone, setPhone] = useState([]);
    const [name, setName] = useState("");
    const [grade, setGrade] = useState("");
    const [classNum, setClassNum] = useState("");
    const [megama, setMegama] = useState([]);
    const [student, setStudent] = useState([]);
    const [username, setUsername] = useState([]);
    const [filename, setFilename] = useState([]);
    const [allLessons, setAllLessons] = useState(false);
    const [countBy, setCountBy] = useState("");
    const [limit, setLimit] = useState("");
    const [weekdays, setWeekdays] = useState([]);
    const [folderStart, setFolderStart] = useState("");
    const [groupByFolder, setGroupByFolder] = useState(false);
    const [groupByFile, setGroupByFile] = useState(true);

    useEffect(() => {
        setFromDate(params.fromDate || defaultFromDate);
        setToDate(params.toDate || "");
        setKlass(params.klass || []);
        setLesson(params.lesson || []);
        setLessonCategory(params.lessonCategory || []);
        setFromSeconds(params.fromSeconds || "");
        setToSeconds(params.toSeconds || "");
        setExtension(params.extension || "");
        setMessageName(params.messageName || "");
        setIdentityNumber(params.identityNumber || "");
        setPhone(params.phone || "");
        setName(params.name || "");
        setGrade(params.grade || "");
        setClassNum(params.classNum || "");
        setMegama(params.megama || []);
        setStudent(params.student || []);
        setUsername(params.username || []);
        setFilename(params.filename || []);
        setAllLessons(params.allLessons || false);
        setCountBy(params.countBy || "");
        setLimit(params.limit || "");
        setWeekdays(params.weekdays || []);
        setFolderStart(params.folderStart || "");
        setGroupByFolder(params.groupByFolder || false);
        setGroupByFile(params.groupByFile !== false);
    }, [params, defaultFromDate]);

    const getDataToSubmit = () => ({
        fromDate,
        toDate,
        klass,
        lesson,
        lessonCategory,
        fromSeconds,
        toSeconds,
        extension,
        messageName,
        identityNumber,
        phone,
        name,
        grade,
        classNum,
        megama,
        student,
        username,
        filename,
        allLessons,
        countBy,
        limit,
        weekdays,
        folderStart,
        groupByFolder,
        groupByFile,
    });

    return {
        state: {
            fromDate,
            toDate,
            klass,
            lesson,
            lessonCategory,
            fromSeconds,
            toSeconds,
            extension,
            messageName,
            identityNumber,
            phone,
            name,
            grade,
            classNum,
            megama,
            student,
            username,
            filename,
            allLessons,
            countBy,
            limit,
            weekdays,
            folderStart,
            groupByFolder,
            groupByFile,
        },
        setters: {
            setFromDate,
            setToDate,
            setKlass,
            setLesson,
            setLessonCategory,
            setFromSeconds,
            setToSeconds,
            setExtension,
            setMessageName,
            setIdentityNumber,
            setPhone,
            setName,
            setGrade,
            setClassNum,
            setMegama,
            setStudent,
            setUsername,
            setFilename,
            setAllLessons,
            setCountBy,
            setLimit,
            setWeekdays,
            setFolderStart,
            setGroupByFolder,
            setGroupByFile,
        },
        getDataToSubmit
    };
};