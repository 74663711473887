export default [
    {
        url: "",
        title: "גרפים כלליים",
        filterFields: ["dateRange", "filename", "secondRange", "countBy", "limit", "weekdays", "folderStart"],
        isAdmin: false,
    },
    {
        url: "graphByKlass",
        title: "גרפים לפי כיתה",
        filterFields: ["dateRange", "singleKlass", "lessonCategory", "lesson"],
        isAdmin: false,
    },
];
